<template>
  <v-container class="pa-0" fluid>
    <v-container class="px-md-16">
      <kurcc-category-card-list/>
      <kurcc-site-card-list :title="$vuetify.lang.t('$vuetify.pages.home.topGradeSites')"/>
    </v-container>
    <kurcc-contact-us-promo/>
    <v-container class="px-md-16">
      <kurcc-site-card-list :title="$vuetify.lang.t('$vuetify.pages.home.topRatedSites')"/>
    </v-container>
    <kurcc-submit-site-promo/>
    <v-container class="px-md-16">
      <kurcc-site-card-list :title="$vuetify.lang.t('$vuetify.pages.home.recentSites')"/>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'KurccHomePageMain',
  components: {
    KurccCategoryCardList: () => import('@/modules/home/components/KurccCategoryCardList'),
    KurccSiteCardList: () => import('@/modules/home/components/KurccSiteCardList'),
    KurccContactUsPromo: () => import('@/modules/home/components/KurccContactUsPromo'),
    KurccSubmitSitePromo: () => import('@/modules/home/components/KurccSubmitSitePromo')
  }
}
</script>
